<template>
  <div
    class="row justify-center q-gutter-md items-start"
    :class="isSmallScreen ? 'q-pa-xs' : 'q-pa-xl'"
  >
    <q-card
      flat
      bordered
      style="width: 300px;"
      v-for="(val, i) in filteredMenus"
      :key="i"
    >
      <q-img style="height: 170px;" :src="val.img">
        <div class="absolute-bottom text-subtitle2 text-capitalize">
          {{ val.title }}
        </div>
      </q-img>
      <q-separator></q-separator>
      <q-list separator style="width:100%;">
        <q-item
          v-for="(val2, j) in val.links"
          :key="j"
          clickable
          v-ripple
          :to="val2.to"
          v-can="val2.isCan"
        >
          <q-item-section avatar top>
            <q-icon :name="val2.icon" color="blue-4"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ val2.text }}</q-item-label>
            <q-item-label caption>{{ val2.caption }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
export default {
  computed: {
    isSmallScreen() {
      return this.$q.screen.lt.md;
    },
    filteredMenus() {
      if (this.isSmallScreen)
        return this.menus.filter((menu) => menu.small_screen);
      else return this.menus;
    },
  },
  data() {
    return {
      menus: [
        {
          title: "siswa",
          small_screen: false,
          links: [
            {
              to: "/siswaimportdata",
              text: "Import data siswa",
              icon: "import_export",
              caption: "import file excel dari Dapodik, EMIS atau PPDB",
              isCan: ["ADMIN", "AKADEMIK"],
            },
            {
              to: `/siswalist/${this.$LIST_JENJANG[0]}`,
              text: "List siswa",
              icon: "switch_account",
              caption: "lihat dan edit list siswa yang sudah terdaftar",
              isCan: ["ADMIN", "AKADEMIK", "PENGASUHAN"],
            },
            {
              to: "/siswainputbeasiswa",
              text: "List Penerima Beasiswa",
              icon: "school",
              caption: "Mengisi List Beasiswa dan Penerima Beasiswa",
              isCan: ["ADMIN", "AKADEMIK"],
            },
          ],
          img:
            "https://st3.depositphotos.com/5728490/16136/v/1600/depositphotos_161369366-stock-illustration-cv-resume-template-design-with.jpg",
        },
        {
          title: "akademik",
          small_screen: false,
          links: [
            {
              to: `/akademiklistguru/${this.$LIST_JENJANG[0]}`,
              text: "List guru",
              icon: "groups",
              caption: "lihat dan edit list guru",
              isCan: ["ADMIN", "AKADEMIK"],
            },
            {
              to: "/akademiklistkelas",
              text: "List rombongan belajar",
              icon: "festival",
              caption: "lihat dan edit list rombongan belajar",
              isCan: ["ADMIN", "AKADEMIK"],
            },
            {
              to: "/akademikisikelas",
              text: "Isi rombongan belajar",
              icon: "room_preferences",
              caption: "ubah anggota dari sebuah kelas",
              isCan: ["ADMIN", "AKADEMIK"],
            },
            {
              to: "/akademikriwayatraport",
              small_screen: false,
              text: "Riwayat Raport",
              icon: "menu_book",
              caption: "Melihat Riwayat Raport Peserta Didik",
              isCan: ["ADMIN", "AKADEMIK"],
            },
          ],
          img:
            "https://image.freepik.com/free-vector/school-boy-learning-online-course-mobile_301430-2.jpg",
        },
        {
          title: "pengasuhan",
          small_screen: false,
          links: [
            {
              to: "/pengasuhanlistmusyrif/" + this.$LIST_PENGASUHAN[0],
              text: "List Pembina",
              icon: "groups",
              caption: "lihat dan edit list Pembina",
              isCan: ["ADMIN", "PENGASUHAN"],
            },
            {
              to: "/pengasuhanlistasrama",
              text: "List asrama",
              icon: "house",
              caption: "lihat dan edit list asrama",
              isCan: ["ADMIN", "PENGASUHAN"],
            },
            {
              to: "/pengasuhanlistkamar",
              text: "List kamar",
              icon: "hotel",
              caption: "lihat dan edit list kamar pada asrama",
              isCan: ["ADMIN", "PENGASUHAN"],
            },
            {
              to: "/pengasuhanisikamar",
              text: "Isi kamar",
              icon: "room_preferences",
              caption: "ubah anggota dari sebuah kamar",
              isCan: ["ADMIN", "PENGASUHAN"],
            },
            {
              to: "/pengasuhanisimusyrif",
              text: "Isi Pembina",
              icon: "escalator_warning",
              caption: "Menempatkan santri dengan Pembinanya",
              isCan: ["ADMIN", "PENGASUHAN"],
            },
            {
              to: "/pengasuhanlistmudabbir/-1",
              text: "List Mudabbir/ah",
              icon: "manage_accounts",
              caption: "lihat dan edit list mudabbir/ah",
              isCan: ["ADMIN", "PENGASUHAN"],
            },
          ],
          img:
            "https://cdn.dribbble.com/users/1314508/screenshots/6470860/img_0457_4x.jpg?compress=1&resize=400x300",
        },
        {
          title: "statistik",
          small_screen: true,
          links: [
            {
              to: "/statistikdaftarkamar/-1",
              text: "Daftar Kamar",
              icon: "people",
              caption:
                "lihat list dari kamar beserta data siapa Pembina, mudabbir/ah dan ketua kamar",
              isCan: ["ADMIN", "YAYASAN"],
            },
            {
              to: "/statistikgrafikpertumbuhan",
              text: "Pertumbuhan jumlah siswa",
              icon: "timeline",
              caption: "lihat grafik pertumbuhan keluar masuknya siswa",
              isCan: ["ADMIN", "YAYASAN", "PENGASUHAN", "AKADEMIK"],
            },
            {
              to: "/statistiksiswa/" + this.$LIST_JENJANG[0],
              text: "Statistik siswa",
              icon: "wysiwyg",
              caption: "statistik siswa secara menyeluruh",
              isCan: ["ADMIN", "YAYASAN"],
            },
            {
              to: "/statistikstatussiswa/" + this.$LIST_JENJANG[0] + "/-1",
              text: "Statistik Pergerakan Siswa",
              icon: "wysiwyg",
              caption: "Melihat Perubahan Status Siswa Per-Semester",
              isCan: ["ADMIN", "YAYASAN"],
            },
            {
              to: "/statistikprestasi",
              text: "Statistik prestasi dan beasiswa",
              icon: "wysiwyg",
              caption: "statistik penerima beasiswa dan peraih prestasi",
              isCan: ["ADMIN", "YAYASAN"],
            },
            {
              to: "/statistikorangtua",
              text: "Statistik orang tua dan wali",
              icon: "wysiwyg",
              caption: "statistik orang tua dan wali secara menyeluruh",
              isCan: ["ADMIN", "YAYASAN"],
            },
            {
              to: "/statistiksebaranalumni",
              text: "Sebaran alumni",
              icon: "follow_the_signs",
              caption: "lihat grafik dan list sebaran alumni",
              isCan: ["ADMIN", "YAYASAN"],
            },
            {
              to: "/statistikkehadiransiswa",
              text: "Kehadiran Siswa",
              icon: "hail",
              caption: "Melihat evaluasi Kehadiran Siswa di Sekolah",
              isCan: ["ADMIN", "YAYASAN"],
            },
          ],
          img:
            "https://image.freepik.com/free-vector/business-statistics-illustration_124046-162.jpg",
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
